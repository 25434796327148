import { Block, Content, createComponent, Flex, IntrinsicProps, Slot, Title } from 'react-commons';
import { App, Layout, Container } from 'react-commons';
import Image from 'next/image';

import moduleStyle from './index.module.scss';
import headerStyle from '@/components/AppHeader/index.module.scss';
import footerStyle from '@/components/AppFooter/index.module.scss';
import PerDevice from '@/components/PerDevice';

const style = {
  ...moduleStyle,
  ...headerStyle,
  ...footerStyle,
};

export default createComponent('MinimalLayout', { style }, function DefaultLayout ({ className }, props) {
  return (
    <Layout className={className}>
      <App.Header>
        <div className={'AppHeader'}>
          <div className='Flex Flex--alignCenter Flex--wide AppHeader__DesktopHeader Container'>
            {/* Logo */}
            <Flex>
              <a className='AppHeader__Logo'>
                <Image
                  src='/images/shockwave.svg'
                  alt='Shockwave.com'
                  width={164}
                  height={57}
                />
              </a>
            </Flex>
          </div>
        </div>
      </App.Header>

      <App.Main>
        <Container>
          {props.children}
        </Container>
      </App.Main>

      <App.Footer>
        <div className='AppFooter'>
          <Container>
            <Content>
              <Block>
                <Title h3 size6>Free Online Games and More</Title>
                <p>Part of the Addicting Games network.</p>
                <p>Copyright © 2023 Addicting Games, Inc; all rights reserved.</p>
              </Block>
            </Content>
          </Container>
        </div>
      </App.Footer>
    </Layout>
  );
});
