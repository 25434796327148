import { useLottie } from 'lottie-react';
import { createPage, Block, Content, Title, Flex } from 'react-commons';

import Meta from '@/components/Meta';
import MinimalLayout from '@/components/layouts/MinimalLayout';
import Pod from '@/components/Pod';

import shockwaveDudeAnimation from '@/lib/lottie/shockwaveDude.json';

import style from './index.module.scss';

export interface MaintenancePageProps {
}

export default createPage<MaintenancePageProps>('MaintenancePage', { style }, function MaintenancePage ({ className }) {
  const { View: LottieAnimation } = useLottie({
    animationData: shockwaveDudeAnimation,
    loop: true
  });

  return (
    <MinimalLayout className={className}>

      {/* Meta information */}
      <Meta 
        title='Shockwave Games'
        titleTag='Shockwave Games'
      />

      <Pod gutter='none'>
        <div className='MaintenancePage__Inner'>
          <Block>
            <Content className='--textCenter'>
              <Title h2 size5>Sorry about that!</Title>
              <p><strong>Shockwave is currently down for maintenance. We'll be back soon!</strong></p>
            </Content>
          </Block>
          <Block>
            <Flex alignCenter justifyCenter wide>
              <div className='MaintenancePage__Lottie'>
                {LottieAnimation}
              </div>
            </Flex>
          </Block>
        </div>
      </Pod>

    </MinimalLayout>
  );
});
